.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}

.no-select {
    user-select: none;
}

.flex-no-shrink {
    flex-shrink: 0;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex {
    display: flex;
}

.flex-start-center {
    justify-content: flex-start;
    align-items: center;
}

.flex-start {
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-grow {
    flex-grow: 1;
}

.text-large {
    font-size: 1.125em;
}

.text-medium {
    font-size: 0.875em;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;   
}

.align-center {
    text-align: center;   
}

.text-wrap {
    word-wrap: break-word;
    overflow: hidden;
    white-space: normal !important;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.pointer {
    cursor: pointer;
}
