@font-face {
	font-family: 'Montserrat';
	src: url('../assets/fonts/Monserrat/Montserrat-Thin.ttf') format("truetype");
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../assets/fonts/Monserrat/Montserrat-ExtraLight.ttf') format("truetype");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../assets/fonts/Monserrat/Montserrat-Light.ttf') format("truetype");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../assets/fonts/Monserrat/Montserrat-Regular.ttf') format("truetype");
	font-weight: 400; // normal
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../assets/fonts/Monserrat/Montserrat-Medium.ttf') format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../assets/fonts/Monserrat/Montserrat-SemiBold.ttf') format("truetype");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../assets/fonts/Monserrat/Montserrat-Bold.ttf') format("truetype");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../assets/fonts/Monserrat/Montserrat-ExtraBold.ttf') format("truetype");
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../assets/fonts/Monserrat/Montserrat-Black.ttf') format("truetype");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

// Roboto Mono

@font-face {
	font-family: 'Roboto Mono';
	src: url("../assets/fonts/Roboto/RobotoMono-Thin.ttf") format("truetype");
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Mono';
	src: url("../assets/fonts/Roboto/RobotoMono-ExtraLight.ttf") format("truetype");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Mono';
	src: url("../assets/fonts/Roboto/RobotoMono-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Mono';
	src: url("../assets/fonts/Roboto/RobotoMono-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Mono';
	src: url("../assets/fonts/Roboto/RobotoMono-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Mono';
	src: url("../assets/fonts/Roboto/RobotoMono-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Mono';
	src: url("../assets/fonts/Roboto/RobotoMono-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
