// Use it on Icon component (need to fix the misleading naming)
@mixin rounded-icon-button($size, $backgroundColor, $color, $padding: "") {
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: $backgroundColor;
    fill: $color;
    stroke: $color;
    cursor: pointer;

    @if $padding != "" {
        padding: #{$padding};
    } @else {
        padding: $size / 4;
    }
    & > svg {
        background-color: $backgroundColor;
    }
}
/*Bootstrap buttons*/

// body added to avoid specificity issues
body {
    // TODO: rename to more generic className if used in different context rather than header
    .btn {
        @mixin main-action {
            border-radius: 100px;
            height: 38px;
            padding: 8px 20px;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
            &:focus {
                box-shadow: none;
            }
        }

        // Dimensions
        &.btn-main-action,
        &.btn-main-action--clear,
        &.btn-main-action--outline,
        &.btn-primary-action--outline,
        &.btn-secondary-action,
        &.btn-danger,
        &.btn-danger--outline {
            @include main-action;
            .Icon {
                width: 24px;
                height: 24px;
                margin: 0 4px 0 0;
            }
        }

        // Colors
        &.btn-main-action {
            background-color: var(--color_primary);
            color: var(--color_white_1);
            &:hover {
                background-color: var(--color_primary);
                color: var(--color_white_1);
            }
            .Icon {
                stroke: var(--color_white_1);
                fill: var(--color_white_1);
                &.inverted {
                    fill: var(--color_white_1);
                    stroke: var(--color_primary);
                }
            }
        }

        &.btn-main-action--outline {
            background-color: var(--color_white_1);
            color: var(--color_gray_1);
        }

        &.btn-primary-action--outline {
            background-color: transparent;
            color: var(--color_primary);
        }

        &.btn-danger--outline {
            background-color: transparent;
            color: var(--color_danger);
            &:hover {
                background-color: transparent;
                color: var(--color_danger);
            }
        }

        &.btn-main-action--clear {
            background-color: rgba(var(--color_primary--rgb), 0.2);
            color: var(--color_primary);
            &:hover {
                background-color: rgba(var(--color_primary--rgb), 0.2);
                color: var(--color_primary);
            }
            .Icon {
                stroke: var(--color_primary);
                fill: var(--color_primary);
            }
        }

        &.btn-main-action--dark {
            background-color: transparent;
            color: var(--color_primary);
            &:hover,
            &:focus {
                background-color: transparent;
                color: var(--color_primary);
                outline: none;
                box-shadow: none;
            }
            .Icon {
                stroke: var(--color_primary);
                fill: var(--color_primary);
            }
        }

        &.btn-secondary-action {
            background-color: var(--color_gray_5);
            &:hover {
                background-color: var(var(--color_gray_5));
                color: var(--color_black_1);
            }
            .Icon {
                stroke: var(--color_black_1);
                width: 24px;
                height: 24px;
                margin: 0 4px 0 0;
            }
        }

        &.btn-danger {
            background-color: var(--color_danger);
            &:hover {
                background-color: var(var(--color_danger));
                color: var(--color_white_1);
            }
            .Icon {
                stroke: var(--color_white_1);
                &.inverted {
                    fill: var(--color_white_1);
                    stroke: var(--color_primary);
                }
            }
        }

        &.btn-danger {
            background-color: var(--color_danger);
            &:hover {
                background-color: var(var(--color_danger));
                color: var(--color_white_1);
            }
            .Icon {
                stroke: var(--color_white_1);
                width: 24px;
                height: 24px;
                margin: 0 16px;
            }
        }

        &.btn-outline,
        &.btn-primary {
            margin: 2px;
            padding: 0px 11px;
            font-size: 14px;
            height: 26px;
            border-radius: 4px;
            font-weight: 300;
        }
        &.btn-primary {
            background-color: var(--color_primary);
            color: var(--color_white_1);
            border: 1px solid var(--color_primary);
            &:hover {
                background-color: var(--color_primary);
                color: var(--color_white_1);
                border: 1px solid var(--color_primary);
            }
            &:disabled {
                background-color: var(--color_primary_1);
                border: 1px solid var(--color_primary_1);
            }
        }

        &.btn-outline {
            background-color: var(--color_white_1);
            color: var(--color_primary);
            border: 1px solid var(--color_black_5);
            &:focus {
                outline: none;
                box-shadow: none;
            }
            &:hover {
                background-color: var(--color_white_1);
                color: var(--color_primary);
                border: 1px solid var(--color_black_5);
            }
            &:disabled {
                background-color: var(--color_white_1);
                color: var(--color_black_5);
                border: 1px solid var(--color_black_5);
            }
            // TODO: update when having styles for it
            &--checked {
                background-color: var(--color_gray_4);
                color: var(--color_primary);
                &:hover {
                    background-color: var(--color_gray_4);
                    color: var(--color_primary);
                }
            }
        }

        &.btn-main-actions-dropdown {
            font-size: var(--fontSize_p);
            background-color: transparent;
            border-radius: 10px;
            font-weight: 600;
            padding: 0.25rem 0.5rem;
            &:focus,
            &:active {
                background-color: var(--color_gray_5);
                outline: none;
                box-shadow: none;
            }

            .dropdown-menu {
                border: none;
            }
        }
        &.btn-thin {
            height: 2rem;
            width: 8.813rem;
            padding: 0 2.5rem;
        }
        &.upload {
            &,
            &:focus,
            &:hover,
            &:active,
            &:visited {
                padding-left: 0;
                padding-right: 0;
                box-shadow: none;
                background-color: var(--color_gray_2);
                border: none;
                outline: none;
            }
        }
        &.file-upload {
            &,
            &:focus,
            &:hover,
            &:active,
            &:visited {
                width: 100%;
                box-shadow: none;
                border: none;
                outline: none;
            }
        }
    }
    // React bootstrap button group
    .btn-group {
        background-color: var(--color_gray_4);
        border-radius: 8px;
        .btn {
            font-weight: 600;
            margin: 0;

            &:focus {
                outline: 0;
                box-shadow: none;
                border: none;
            }
            &:first-child {
                margin-right: 0;
            }
            &:last-child {
                margin-left: 0;
            }
        }
        &.small {
            font-size: 14px;
            > .btn {
                border-radius: 8px;
                padding: 4px 7px;
                &:not(.dropdown-toggle) {
                    border-radius: 8px !important;
                }
            }
        }
        &.medium {
            font-size: 14px;
            > .btn {
                border-radius: 8px;
                padding: 12px 10px;
                height: 38px;
                &:not(.dropdown-toggle) {
                    border-radius: 8px !important;
                }
            }
        }
        &.primary {
            > .btn {
                background-color: var(--color_gray_4);
                color: var(--color_white_1);
                border: none;
                &.selected,
                &:active,
                &:focus,
                &:visited {
                    background-color: var(--color_gray_4);
                }
                &.selected {
                    background-color: var(--color_primary);
                }
            }
        }
    }

    /*Material-ui buttons*/
    .MuiButtonBase-root {
        &.icon-button-row-action {
            padding: 4px;
            color: var(--color_primary);
            fill: transparent;
            stroke: var(--color_primary);
            border-radius: 8px;
            &:focus,
            &:hover {
                background-color: rgba(var(--color_gray_3--rgb), 0.4);
                outline: none;
                fill: transparent;
                stroke: var(--color_primary);
            }

            &.Mui-disabled {
                opacity: 0.2;
            }

            &--danger {
                padding: 4px;
                fill: transparent;
                border-radius: 8px;
                color: var(--color_danger);
                stroke: var(--color_danger);
                &:focus,
                &:hover {
                    color: var(--color_danger);
                    stroke: var(--color_danger);
                }
            }
        }
    }

    /*Material-ui checkboxes*/
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: var(--color_primary);
    }
}

