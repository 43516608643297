@import "functions";

$colors: (
    primary: #00aeef,
    primary_1: #99dff9,
    secondary: #00a79d,
    danger: #ef4c00,
    success: #2ecc71,
    success_1: #2FCC71,
    info: #5dade1,
    warning: #f2c94c,
    black_1: #414042,
    black_2: #626163,
    black_3: #a5a5a5,
    black_4: #c7c7c7,
    black_5: #d7d7d8,
    gray_1: #828282,
    gray_2: #d9d9d9,
    gray_3: #cdcdcd,
    gray_4: #e1e1e1,
    gray_5: #f1f3f4,
    white_1: #ffffff,
    red_1: #ff0000,
    red_2: #FADBD8,
    purple_1: #A33AA3,
);

:root {
    @each $color, $value in $colors {
        --color_#{$color}: #{$value};
    }
    // TODO: Look for a way of generating transparent color pallete in a loop
    // IMPORTANT:
    --color_white--rgb: #{hexToRGB(map-get($colors, "white_1"))};
    --color_primary--rgb: #{hexToRGB(map-get($colors, "primary"))};
    --color_black_1--rgb: #{hexToRGB(map-get($colors, "black_1"))};
    --color_gray_2--rgb: #{hexToRGB(map-get($colors, "gray_2"))};
    --color_gray_1--rgb: #{hexToRGB(map-get($colors, "gray_1"))};
    --color_secondary--rgb: #{hexToRGB(map-get($colors, "secondary"))};
    --color_gray_3--rgb: #{hexToRGB(map-get($colors, "gray_3"))};
    --color_gray_5--rgb: #{hexToRGB(map-get($colors, "gray_5"))};
    --color_red_1--rgb: #{hexToRGB(map-get($colors, "red_1"))};
    --color_primary--rgba-16: rgba(0, 176, 240, 0.16);
    --color_success--rgb: #{hexToRGB(map-get($colors, "success"))};
    --color_purple_1--rgb: #{hexToRGB(map-get($colors, "purple_1"))};
    --color_danger--rgb: #{hexToRGB(map-get($colors, "danger"))};
    --color_warning--rgb: #{hexToRGB(map-get($colors, "warning"))};
}
