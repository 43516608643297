@import "styles/fonts";
@import "styles/master";

* {
    margin: 0;
    font-family: "Montserrat", sans-serif !important;
}

body {
    margin: 0;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    color: var(--color_black_1);
}

#root {
    height: 100%;
}

body {
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: var(--fontWeight_normal);
        margin: 0;
    }

    h1 {
        font-weight: var(--fontWeight_bold);
        font-size: var(--fontSize_h1);
        line-height: 42px !important;
        color: var(--fontColor_h1);
    }

    h2 {
        font-weight: var(--fontWeight_bold);
        font-size: var(--fontSize_h2);
    }

    h3 {
        font-size: var(--fontSize_h3);
    }

    h4 {
        font-size: var(--fontSize_h4);
    }

    h5 {
        font-size: var(--fontSize_h5);
    }

    h6 {
        font-size: var(--fontSize_h6);
    }

    p {
        font-size: var(--fontSize_p);
    }

    input {
        color: var(--fontColor_default);
    }

    ul {
        padding-inline-start: 0;
    }
    a {
        color: var(--color_primary);
    }
}
