@import "./../../styles/master";

.Header {
    position: sticky;
    display: flex;
    padding: 3px 32px;
    justify-content: space-between;
    align-items: center;
    background-color: var(--backGroundColor_default);
    top: 0;
    border-bottom: 2px solid var(--color_gray_5);
    height: var(--height_header);
    z-index: 4;

    .separator {
        height: 100%;
        color: var(--color_gray_5);
        font-size: 2.1875rem;

        &::before {
            content: "|";
        }
    }

    .HeaderLeft {
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        &__navigate {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #A6A6A6;
            margin-left:20px;
        }

        &__navigate_title {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: black;
            margin-left:4px;
            margin-right:20px;
        }

        &__navigate_icon {
            background-color: #D9D9D9 !important;
            color: #8C8C8C !important;
            background-color: #D9D9D9 !important;
            color: #8C8C8C !important;
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-width: 0px;
            border-radius: 25px;
            padding: 0px;
            
            svg {
                height: 18px;
                width: 18px;
            }
        }
        &__title {
            padding-right: 32px;
        }

        &__beta {
            border-radius: 5px;
            background-color: #F7DC6E;
            color: #917608;
            padding: 0.2em 1em;
            font-weight: 600;
            font-size: 14px;
            margin-right: 0.5em;
        }

        &__Actions {
            display: flex;
            align-items: center;
            > * {
                height: 30px;
                border-radius: 6px;
                padding: 3px 5px;
                background-color: var(--color_white_1);
                stroke: var(--color_black_1);
                cursor: pointer;

                &.active {
                    background-color: transparent;
                    background-color: var(--color_primary);
                }
            }
        }
    }

    .HeaderCenter {
        display: flex;
        flex: 1;
        align-items: center;
        margin-left: 34px;
        font-size: var(--fontSize_p);
        color: var(--color_gray_3);
        font-weight: 600;
        overflow: hidden;

        &__separator {
            padding: 0 8px;
        }
        &__name {
            cursor: pointer;
            color: var(--color_black_1);
            font-size: var(--fontSize_p);
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .HeaderRight {
        display: flex;
    }
}

.HeaderInfo {
    display: flex;
    align-items: center;
}

.HeaderInfo > h4 {
    margin-left: 10px;
}

.HeaderOption {
    cursor: pointer;
    display: flex;
    height: 32px;
    padding: 0 6px;
    flex: 1;
}

.HeaderOption .Option {
    fill: var(--color_gray_1);
    stroke: var(--color_gray_1);
    font-size: 20px;
    margin-left: 22px;
    user-select: none;
    
    height: 32px;
    flex: 1;

    &.Option--toggle {
        width: 32px;
        svg {
            border-radius: 50%;
            padding: 4px;
            color: var(--color_gray_1);
            background-color: rgba(var(--color_gray_3--rgb), 0.2);

            &.active {
                background-color: rgba(var(--color_primary--rgb), 0.2);
                color: var(--color_primary);
                stroke: var(--color_primary);
                fill: var(--color_primary);
            }
        }
    }
}

.header-right-actions {
    margin-left: 29px;
    display: flex;
    align-items: center;

    > * {
        margin-left: 32px;
    }
}
