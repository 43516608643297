@import "colors";

@mixin input($fontSize: 16px) {
    border: 1px solid var(--color_gray_3);
    color: var(--color_black_1);
    border-radius: 4px;
    font-size: $fontSize;
    font-weight: 500;
    outline: none;
    width: 100%;
    height: 100%;
    &:focus {
        border: 1px solid var(--color_black_3);
    }
    &::placeholder {
        color: var(--color_gray_3);
        opacity: 1;
    }
}

body {
    .MuiInputBase-input,
    .MuiTypography-body1 {
        font-family: "Montserrat";
    }
    .MuiRadio-root.MuiRadio-colorSecondary {
        padding: 0 6px 0 8px;
        &:checked,
        &:hover {
            background-color: unset;
        }
        .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
        }
    }
    .MuiFormControlLabel-label {
        font-size: 12px;
        font-weight: 500;
    }
    .MuiSwitch-colorPrimary.Mui-checked {
        color: var(--color_primary);
    }

    .MuiRadio-colorSecondary.Mui-checked {
        color: var(--color_primary);
    }

    .MuiIconButton-root.MuiCheckbox-root {
        padding: 0 6px 0 8px;
    }

    // Autocomplete
    // TODO: Consider moving into separate file
    .MuiAutocomplete-root {
        background-color: var(--color_white_1);
        .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
            height: 20px;
            padding: 0;
        }
    }

    .custom-switch .custom-control-input {
        transform: translateX(1.125rem);
    }

    .custom-control {
        z-index: unset;
        &.custom-radio {
            font-size: 14px;
            padding: 0 0 0 22px;
        }
    }

    .custom-switch {
        margin-left: 1.125rem;
        z-index: auto;

        .custom-control-input {
            &:checked ~ .custom-control-label::before,
            &:focus ~ .custom-control-label::before,
            &:not(:disabled):active ~ .custom-control-label::before {
                background-color: var(--color_success);
                outline: 0;
                box-shadow: none;
                border: none;
            }

            &:focus:not(:checked) ~ .custom-control-label::before {
                background-color: var(--color_gray_3);
            }

            &:checked ~ .custom-control-label::after {
                transform: translateX(2.25rem);
            }
        }

        .custom-control-label {
            position: static;
            line-height: 2.125rem;
            user-select: none;
            font-size: var(--fontSize_small);
            color: var(--color_black_2);
            &::before {
                height: 1.5rem;
                width: 2.625rem;
                left: -1.125rem;
                border-radius: 0.75rem;
                background-color: var(--color_gray_3);
            }
            &:after {
                background-color: var(--color_white_1);
                top: calc(0.375rem + 1px);
                left: calc(-2.25rem + 1px);
                width: calc(1.5rem - 6px);
                height: calc(1.5rem - 6px);
                border-radius: 0.75rem;
                transform: translateX(calc(1.125rem + 1px));
                transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
            }
        }
    }

    .form-group {
        margin-bottom: 0.25rem;
        & > .form-label {
            margin-bottom: 0;
            color: var(--color_gray_2);
            font-size: var(--fontSize_small);
        }
        & > .form-control {
            height: calc(1.25em + 0.75rem + 2px);
            font-size: var(--fontSize_small);
            border: 1px solid var(--color_gray_2);
            color: var(--fontColor_default) !important;
            font-weight: var(--fontWeight_semibold) !important;

                &.form-area{
                    height: auto;
                }
        }
        &.hidden {
            display: none;
        }
    }
    .form-check {
        display: flex;
        align-items: center;
        .form-check-input {
            margin-top: 0;
        }
    }
    .custom-control-inline {
        margin-right: 8px;
        .custom-control-label {
            padding-top: 3px;
        }
    }
    .file-upload {
        background-color: var(--color_primary);
        border-radius: 20px;
        color: var(--color_white_1) !important;
        cursor: pointer;
        width: 100%;
        text-align: center;
        height: 40px;
        padding: 6px 40px;
        font-size: 14px;
        font-weight: 600;
        line-height: 2;
        & > svg {
            width: 24px;
            height: 24px;
            stroke: var(--color_white_1);
            margin-right: 20px;
        }
    }

    .input-slider {
        width: 100%;
        .MuiSlider-root {
            color: var(--color_primary);
            height: 8px;
        }
        .MuiSlider-thumb {
            height: 24px;
            width: 24px;
            border: 2px solid var(--color_primary);
           // margin-top: -8px;
           // margin-left: -12px;
        
            &:focus, &:hover {
                box-shadow: 0px 0px 0px 12px var(--color_primary--rgba-16);
            }
            &.MuiSlider-active {
                box-shadow: 0px 0px 0px 12px var(--color_primary--rgba-16);
            }
        }
        
        
        .MuiSlider-valueLabel {
            left: calc(-50% + 4px);
        }
        .MuiSlider-track {
            height: 8px;
            border-radius: 4px;
        }
        .MuiSlider-rail {
            height: 8px;
            border-radius: 4px;
        }
    }
    
}
