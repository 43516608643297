@import "../../../styles/buttons";


.MultiSelectFilter {
    &__option{
        display: flex;
        flex-direction: column;
    }

    &__checkbox .form-check-input
    {
        width: 20px;
        height: 20px;
        padding: 1px;
    }
}