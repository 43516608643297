@import "../../../styles/buttons";


.DateRangeFilter {
    .ButtonGroup {
        display: flex;
        flex-wrap: wrap;
    }

    .type-date {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        border-radius: 7px;
    }

    .type-active {
        border: var(--color_primary);
        border-radius: 7px;
        position: relative;
    }

    .type-inactive {
        border: var(--color_gray_5);
        color: var(--color_gray_2);
        background-color: var(--color_gray_5);
        border: none;
    }
}