.PhoneCallInteractionCard {
    flex-direction: column;
    display: flex;


    .call-interaction-user {
        flex-direction: row;
        display: flex;
        align-items: center;
        
        label {
            color: var(--color_black_1);
            font-size: var(--fontSize_base);
            font-weight: var(--fontWeight_bold);
            margin-bottom: 0;                
        }

        .call-interaction-dot-icon {
            font-size: 5px;
            color: var(--color_gray_1);
            margin-right: 5px;
            margin-left: 5px;
        }        
    }

    .call-user-roles {
        padding-bottom: 5px;

        label {
            color: var(--color_gray_1);
            font-size: var(--fontSize_small);
            font-weight: var(--fontWeight_semibold);
            margin: 0;            
        }
    }

    .call-interaction-extra-field {
        flex-direction: row;
        display: flex;
        align-items: center;
        
        label {
            font-size: var(--fontSize_h5);
            font-weight: var(--fontWeight_semibold);  
            margin-bottom: 0;                
        }

        .call-interaction-extra-label {
            color: var(--color_gray_1);
            padding-right: 10px;
        }

        .call-interaction-extra-value {
            color: var(--color_black_1);
        }        
    }

    .call-interaction-notes {
        label {
            color: var(--color_gray_1);
            font-size: var(--fontSize_h5);
            font-weight: var(--fontWeight_semibold);  
            margin: 0;            
        }
    }    
}
