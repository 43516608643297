.CalendarDayPicker {

    display: flex;
    flex-direction: column;
    align-items: center;

    .todayBtn, .weekBtn, .monthBtn {
        margin: 5px 4px;
        &.btn {
            &.btn-secondary-action {
                color: var(--color_gray_1);
                &:hover {
                    color: var(--color_gray_1);
                }
            }
        }
    }

    .DayPicker {
        font-size: var(--fontSize_p);
        font-weight: var(--fontWeight_semibold);
        color: var(--color_black_1);

        * {
            outline: none;
        }

        .DayPicker-Week {
            margin-bottom: 5px;
        }

        .DayPicker-Month {
            border-collapse: separate;
            border-spacing: 0 5px;
        }

        .DayPicker-Weekday {
            color: var(--color_gray_2);
            font-weight: var(--fontWeight_bold);
        }

        .DayPicker-Day {
            outline: none;
            border-radius: 0 !important;
            padding: 0;
            width: 35px;
            &--outside {
                color: var(--color_gray_3);
            }
            &--today {
                font-weight: var(--fontWeight_bold);
                color: var(--color_primary);
            }
        }

        .dayWithEventContainer {
            position: relative;
            padding: 0.5em;
        }

        .dayWithEvent {
            position: absolute;
            height: 0;
            width: 0;
            border: 2px solid;
            border-radius: 4px;
            border-color: rgba(var(--color_primary--rgb), 0.4);
            right: 50%;
            transform: translateX(1px);
            top: 70%;
            margin-top: 2px;
        }

        .DayPicker-Day--selected:not(.DayPicker-Day--disabled) {            
            position: relative;
            
            background-color: var(--color_primary);
            color: var(--color_white_1);
            border-radius: 50% !important;

            .dayWithEventContainer {
                .dayWithEvent {
                    border-color: var(--color_white_1);
                }
            }
        }

        &.rangePicker {
            .DayPicker-Day--selected:not(.DayPicker-Day--disabled) {            
                position: relative;
                // Added !importante because it was loosing this attribute when changing pages for some reason
                background-color: rgba(var(--color_primary--rgb), 0.2) !important;
                border-radius: 0% !important;
                
                color: var(--color_black_1);
                .dayWithEvent {
                    border-color: rgba(var(--color_primary--rgb), 0.4);
                }

                &.DayPicker-Day--start {
                    .dayWithEventContainer {
                        background-color: var(--color_primary);
                        color: var(--color_white_1);
                        border-radius: 50% !important;
                        .dayWithEvent {
                            border-color: var(--color_white_1);
                        }
                    }
                    border-top-left-radius: 50% !important;
                    border-bottom-left-radius: 50% !important;
                }
                &.DayPicker-Day--end {
                    .dayWithEventContainer {
                        background-color: var(--color_primary);
                        color: var(--color_white_1);
                        border-radius: 50% !important;
                        .dayWithEvent {
                            border-color: var(--color_white_1);
                        }
                    }
                    border-top-right-radius: 50% !important;
                    border-bottom-right-radius: 50% !important;
                }
            }

        }
        
        .DayPicker-Day--selected:not(.DayPicker-Day--disabled):hover {
            .dayWithEventContainer {
                background-color: var(--color_primary_1);
                border-radius: 50% !important;
            }
        }

        
          
    }

    .DayPicker:not(.DayPicker--interactionDisabled)
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover {
        background-color: var(--color_primary_1);
        border-radius: 50% !important;
    }

    .DayPicker-NavButton {
        width: 2.25em;
        
    }

    .DayPicker-NavButton--prev {
        margin-right: 2.5em;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAALFJREFUSIntlLERwjAMRZ/CAmyAoWMLXyZgBJiAEQgbZAOYhHMZOpggYhFCQbijQzIp/SsX+v+dbEtQVPSvZjmmGOM+hNVatb/9qq384fVBqFqBk6XeBXiH0wAMPHcWj+SGp5TOkwFyw02AGOvt57694WB7g/nXefCEg+GbqvZdCEsRiIJsQlioqt4nA4yQlAsxD1ouxDUHKV2aAY4AQtVaPO5VMXbyAK6qfef1FxX59QJthEqd/9gPLwAAAABJRU5ErkJggg==");
    }
  
    .DayPicker-NavButton--next {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAALJJREFUSIntkLERwjAQBE+GAujAL2V08SUQkjFUQAvQASW4DCIhMkJ3oCd0Bg1gEmZIGPySTaaNPri7nXmgUBjLTBNi5h2RW4rE9i8CS+5kgLUjiyjxMrmAqO4MzAoAp0pUAhFpiepbjkQlGCNRC75JiOxDJF5/daoUwZv+cz7vQ+EkATNvDKoGAAxwCCE0Qx2TO+6D32t6KkHuOKB8Ue44AMw1oR7YAlicgz+mjBcK0/ACbdpGTC7DdVEAAAAASUVORK5CYII=");
    }
}