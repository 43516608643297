@import "./../../styles/master";

:root {
   --filter-visible: 0;
}

$filter-width: 310px;
$filter-border-right-width: 1px;
$filter-horizontal-padding: 0px;
$filter-width-with-border: calc(#{$filter-width} + #{$filter-border-right-width} + #{$filter-horizontal-padding});

.AppBody {
   height: 100%;
}

.GenericListBody {
   overflow: hidden;
}

.GenericList {
   height: 100%;
   overflow: hidden;

   &__content {
      width: calc(100% - (310px * var(--filter-visible))) !important;
      display: flex;
      justify-content: center;
   }

   &__container {
      height: 100%;
      display: flex;
      flex-direction: row;
      overflow: hidden;


      .GenericListFilter {
         border-right: $filter-border-right-width solid var(--color_gray_5);
         overflow-x: hidden;
         transition: all 0.4s;
         width: 0;
         min-width: 0;
         padding: 0;
         top: 0;
         bottom: 0;
         height: calc(100% - 80px);
         z-index: 1020;

         &.open {
            transition: all 0.4s;
            width: $filter-width-with-border;
            min-width: $filter-width-with-border;
         }
      }

      .SpringGrid {
         overflow-x: scroll;
         height: calc(100% - 150px);
         width: 100%;
      }

      .react-bootstrap-table-pagination {
         width: calc(100% - (300px * var(--filter-visible))) !important;
         margin-top: 2.5em;
         position: absolute;
         bottom: 0;
      }
   }
}