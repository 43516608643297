// Bootstrap dialog
.modal-dialog {
    .modal-content {
        padding: 24px;
        border-radius: 0.75rem;
    }
    .modal-header {
        padding: 0;
        display: flex;
        align-items: center;
        border-bottom: none;
        .modal-title {
            text-align: center;
            font-weight: var(--fontWeight_bold);
            font-size: var(--fontSize_p);
            flex: 1;
        }
        .close {
            outline: none;
            margin-top: -24px;
            margin-left: -24px;
            font-size: 24px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            padding: 0;
            background-color: var(--color_gray_5);
            float: right;
        }
    }
    .modal-body {
        padding: 1rem 0;
    }
    .modal-footer {
        padding: 0;
        border-top: none;
    }
    .modal-header .close:focus {
        outline: none !important;
        outline-offset: none !important;
        stroke: var(--color_primary);
    }
}
