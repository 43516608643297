.PaymentInteractionCard {
    flex-direction: column;
    display: flex;

    .payment-value {
        label {
            color: var(--color_black_1);
            font-size: var(--fontSize_base);
            font-weight: var(--fontWeight_bold);
            margin: 0;            
        }
    }
    .payment-interaction-date-and-type {
        flex-direction: row;
        display: flex;
        align-items: center;
                
        label {
            color: var(--color_black_1);
            font-size: var(--fontSize_h5);
            font-weight: var(--fontWeight_semibold);
            margin-bottom: 0;             
        }

        .payment-interaction-dot-icon {
            font-size: 5px;
            color: var(--color_gray_1);
            margin-right: 5px;
            margin-left: 5px;
        }        
    }
}