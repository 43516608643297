$semanticValueList:
    "&.INPROGRESS, &.RECEIVED, &.PUBLISHED" rgba(var(--color_primary--rgb), 0.2) var(--color_primary),
    "&.PLANNED" rgba(var(--color_purple_1--rgb), 0.2) var(--color_purple_1),
    "&.UNKNOWN, &.PAUSED, &.NEUTRAL" rgba(var(--color_gray_3--rgb), 0.2) var(--color-gray_3),
    "&.CANCELLED, &.ERROR, &.REJECTED, &.REJEC, &.BLOCKED, &.EXPIRED, &.NOTGOOD" rgba(var(--color_danger--rgb), 0.2) var(--color_danger),
    "&.FINALIZED, &.SUBMITTED, &.FINALIZING, &.GOOD, &.SETTLED, &.APPRO, &.SENT"  rgba(var(--color_success--rgb), 0.2) var(--color_success),
    "&.PENDING, &.DRAFT, &.SUSPENDED" rgba(var(--color_warning--rgb), 0.2) var(--color_warning);

.color-badge {
    background-color: var(--color_white_1);
    border-radius: 4px;
    width: 120px;
    height: 21px;

    .badge-container {
        width: 100%;
        border-radius: 4px;

        .badge-text {
            font-weight: var(--fontWeight_semibold);
            font-size: var(--fontSize_p);
            margin: 0;
        }

        @each $semanticValue, $containerBgColor, $textColor in $semanticValueList {
            #{$semanticValue} {
                background-color: $containerBgColor;
                .badge-text {
                    color: $textColor;
                }
            }
        }
    }
}


