@import "./../../styles/master";
@import "./../../styles/colors";

.SpringGridSelectAction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 4rem;

  .SelectActionSections {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50%;
  }
  .SelectActionLeftSection {
    justify-content: flex-start;
    .SelectActionItem {
      margin: 0 .5rem 0 .5rem;
    }
    .SelectionCleaner {
      color: var(--color_danger);
      font-weight: var(--fontWeight_bold);
    }
    .SelectionCounter {
      color: var(--color_black_1);
      font-weight: var(--fontWeight_bold);
    }
  }
  .SelectActionRightSection {
    justify-content: flex-end;

    .SelectActionButton {
      margin: 0 .5rem 0 .5rem;
      width: 7rem;
    }
  }
}

.SpringGridSelectActionHide {
  visibility: hidden;
}
