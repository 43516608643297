@mixin icon {
  width: 24px;
  height: 24px;
  fill: transparent;
}

@mixin item {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  background-color: var(--color_white_1);
}

.Detaillist {
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  font-size: 1rem;
  font-weight: 500;
  color: var(--color_black_1);
  stroke: var(--color_black_1);
  cursor: pointer;

  &--disabled {
      cursor: default;
  }
  &--expanded {
      z-index: 10;
      .Detaillist__expand{
        background-color: rgba(var(--color_primary--rgb), 0.4);
      }
  }
  &__container {
      height: 1rem;
      margin: 0;
      padding: 0;
      border-radius: 0.25rem;
  }

  &__expand {
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0.4375rem;
    top: 0.4375rem;
    border-radius: 50%;
    width: 35px;
    height: 35px;

    > svg{
      width: 24px;
      height: 24px;
      stroke: var(--color_primary);
      fill: var(--color_primary_1) !important;
    }
  }

  &__expand:hover {
    background-color: rgba(var(--color_primary--rgb), 0.4);
  }

  &__items {
      background-color: var(--color_white_1);
      border-radius: 8px;
      box-shadow: 2px 4px 8px 2px rgba(65, 64, 66, 0.14);
      margin-top: 12px;
      padding: 8px;
  }
  &__item {
      @include item;
      z-index: 1;

      &:hover {
          background: var(--color_gray_4);
      }
  }
  &__item-empty {
      text-align: center;
  }
}
