.GenericFilter {
    .accordion {
        p {
            font-size: 14px;
            margin-left: -15px;
            font-style: normal;
            font-weight: 600;
            line-height: 17px;
        }
    }

    .MuiAccordion-region {
        div {
            padding: 0px;
        }
    }
}